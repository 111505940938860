<template>
  <div class="row" v-if="cible">
    <div class="col col-md-8">
      <div class="column">
        <base-add-button :label="$t('notes.add_note')" color="per"
          @click="isFormOpen = true" v-if="!isFormOpen" />

        <q-card flat bordered class="q-pa-sm" v-if="isFormOpen">
          <q-card-section>
            <NoteForm @reset="isFormOpen = false" @success="fetchNotes"
              model_type="groupeCible" :model_id="cible.id" color="per" />
          </q-card-section>
        </q-card>

        <NotesTimeline :notes="notes" color="per"
          model_type="groupeCible" :model_id="cible.id"
          @updated="fetchNotes" @delete="deleteNote" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { NoteForm, NotesTimeline } from '../../components/notes'

export default {
  name: 'CibleNotes',
  components: { NoteForm, NotesTimeline },
  props: {
    'form_model': { type: String, default: 'cibles' }
  },

  data () {
    return {
      isFormOpen: false
    }
  },

  computed: {
    ...mapGetters({
      cible: 'cibles/getCurrentCible',
      getNotesFor: 'notes/getNotesFor'
    }),

    notes () {
      return this.getNotesFor('cible', this.cible.id)
    }
  },

  watch: {
    cible (newVal, oldVal) {
      this.fetchNotes()
    }
  },

  methods: {
    fetchNotes () {
      this.isFormOpen = false

      return this.$store.dispatch('notes/fetchNotes', {
        model_type: 'groupeCible',
        model_id: this.cible.id,
        rowsPerPage: 0
      })
    },

    deleteNote (id) {
      this.$confirm(this.$t('notes.delete_note_confirm'), this.$t('form.delete')).onOk(() => {
        this.$store.dispatch('notes/deleteNote', id).then(() => {
          this.$q.notify({ message: this.$t('notes.delete_success'), color: 'positive' })
        }).catch(this.$notifyError)
          .finally(this.fetchNotes)
      })
    }
  }
}
</script>

<style>
</style>
